import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const Error = () => {
  const location = useLocation();
  const isDocumentLockedByUser = useMemo(
      () => new URLSearchParams(location.search).get('documentLockedByUser') === 'true',
      [location.search]
  );

  return (
      <div>
        {isDocumentLockedByUser ? (
            <h4>
              You cannot open this document at the moment as it is currently locked for processing by another user. Please
              retry your request at a later time.
            </h4>
        ) : (
            <h4>You don't have permission for this action.</h4>
        )}
      </div>
  );
};

export default Error;