import { v4 as uuidv4 } from 'uuid';
import {
  ADD_CHECKPOINT,
  APPEND_DOCUMENT_FIELDS,
  APPEND_TAG,
  CHANGE_PAGE,
  CLEAR_LABELS,
  CLEAR_SMART_TAGS,
  DATA_TRANSFER_STATUS,
  DELETE_FIELDS,
  DELETE_TAG,
  DESELECT_FIELD,
  QUERY_DOCUMENT,
  QUERY_DOCUMENT_LABELS,
  QUERY_TEMPLATE,
  REFRESH_FIELD_COMPONENTS,
  REINITIALIZE_CHECKPOINT,
  REPEAT_DOCUMENT_FIELDS,
  SELECT_FIELD,
  SELECT_TAG,
  SET_ACTIVE_SMART_TAG,
  SET_FIELD_SCALE,
  SET_IS_SMART_TAG,
  SET_ORIGIN_SMART_TAGS,
  SET_PIXELPERFECT_OPTION_DISABLED,
  SET_REFERENCE_SMART_TAGS,
  SHOW_LABELS,
  TAGGING_EDITOR_STATE,
  TAGS_BRING_TO_BACK,
  TAGS_BRING_TO_FRONT,
  TOGGLE_TAGS_VISIBILITY,
  UPDATE_DOCUMENT_FIELDS,
  UPDATE_FIELD_TABLE_TYPE,
  UPDATE_SMART_TAG_ORIGIN_TAG,
  UPDATE_VALUE,
  UPSERT_DOCUMENT_FIELDS,
  UPSERT_TAG,
} from './types';
import {
  APPEND_FIELD_TO_TEMPLATE,
  DESELECT_TEMP_FIELD,
  RESET_ACTIVE_TEMPLATE,
  RESET_FIELD_SCALE_VALUE,
  SELECT_TEMP_FIELD,
  UPDATE_TEMPLATE_STATUS,
} from '../store/document/types';
import _, {isEmpty} from 'lodash';
import innolytiqApi from '../api/innolytiqApi';
import mongoose from 'mongoose';
import history from '../history';
import { withDelay } from '../helpers/common/saveHelper';
import {
  assignTagSectionWithKey,
  generateTempField,
  getTableValues,
  getValueForCoordinates,
  isChangedSmartTag,
  processValue,
  toPixels,
} from '../helpers/common/utils';
import {
  getFieldFromFields,
  getFieldFromSmartTagOrigins,
  getOriginFromSmartTagOrigins,
  getTagFromField,
} from '../store/document/utils';
import { saveDocumentTag } from '../store/document/actions';
import { getActiveTemplate } from '../components/Annotation/Browse/BrowseUtils';
import { cleanDocument } from './cleaners';
import { deleteEmptyTag } from '../store/tag/actions';
import { getTagsByRegions, hashFromCoordinates } from '../helpers/annotation/utils';
import { RESET_TAGS_BY_REGIONS, SET_TAGS_BY_REGIONS } from '../store/annotation/types';
import { appendToast } from '../commons/appToastify';
import { DO_NOT_SAVE_DOCUMENT_CHANGES_TEXT, SAVE_DOCUMENT_CHANGES_TEXT } from '../commons/const';
import { getWebUrl, isEnabledLockDocumentByUser } from '../appConfig';

const webUrl = getWebUrl();
const ENABLE_LOCK_DOCUMENT_BY_USER = isEnabledLockDocumentByUser();

const appendTag = (tag, fieldId) => async (dispatch) => {
  dispatch({
    type: APPEND_TAG,
    payload: {
      tag,
      fieldId,
    },
  });
};

const appendFieldToTemplate = (field) => async (dispatch) => {
  dispatch({
    type: APPEND_FIELD_TO_TEMPLATE,
    payload: {
      field,
    },
  });
};
export const deselectTempField = () => async (dispatch) => {
  dispatch({
    type: DESELECT_TEMP_FIELD,
  });
};

export const updateTagOfOriginSmartTag = (_tag, regionInPixels) => async (dispatch, getState) => {
  const {
    document: {
      pageLabels,
      metadata,
      smartTags: { origin: origins },
    },
  } = getState();

  let { srcFieldId, fieldId, ...tag } = _tag;

  const field = getFieldFromSmartTagOrigins(origins, _tag.srcFieldId, _tag.fieldId);

  if (!field) {
    // If was here, that was a bug
    return;
  }

  const { page } = tag;
  regionInPixels.page = page;

  if (field.dataType !== 'table') {
    let value = await getValueForCoordinates(regionInPixels, pageLabels[page]);
    tag = { ...tag, page: page, value: value, ocrValue: value };
    tag = {
      ...tag,
      ...processValue({ field }, page, value, false, tag, metadata),
    };
  } else {
    tag.page = page;
    tag.hasValidValue = tag ? true : field.tags.length > 0;
    tag.hasValidOcrValue = tag ? true : field.tags.length > 0;
    tag.value = `table on page ${page}`;
    tag.ocrValue = `table on page ${page}`;
  }

  dispatch(forceUpdateTagOfOriginSmartTag(tag, srcFieldId, fieldId));
};

export const updateTableOfOriginSmartTag =
  (data, { imgW, imgH }, srcFieldId, fieldId, tagId) =>
  async (dispatch, getState) => {
    const {
      document: {
        activePage = 1,
        pageLabels,
        smartTags: { origin: origins },
      },
    } = getState();

    const field = getFieldFromSmartTagOrigins(origins, srcFieldId, fieldId);
    const tag = getTagFromField(field || {}, tagId);
    if (!tag) {
      // If was here, that was a bug
      return;
    }

    tag.table = await getTableValues(data, imgW, imgH, activePage, pageLabels[activePage]);
    tag.isTable = true;

    dispatch(forceUpdateTagOfOriginSmartTag(tag, srcFieldId, fieldId));
  };

export const forceUpdateTagOfOriginSmartTag = (tag, srcFieldId, fieldId) => async (dispatch) => {
  dispatch({
    type: UPDATE_SMART_TAG_ORIGIN_TAG,
    payload: {
      tag,
      srcFieldId,
      fieldId,
    },
  });
};

export const saveFieldOfOriginSmartTag =
  (srcFieldId, fieldId, tagId, callBack = () => {}) =>
  async (dispatch, getState) => {
    const {
      document: {
        smartTags: { origin: origins },
      },
    } = getState();

    if (!origins) {
      return;
    }

    const originKey = getOriginFromSmartTagOrigins(origins, srcFieldId);
    if (!originKey) {
      // If was here, that was a bug
      return;
    }

    const field = getFieldFromFields(origins[originKey], fieldId);
    const tag = getTagFromField(field, tagId);
    const [templateId] = originKey.split('_');

    dispatch(
      saveDocumentTag(
        {
          templateId: templateId,
          fieldId: field._id,
          tag,
        },
        callBack
      )
    );
  };

export const createTagFromSmartTag = async (field, tagInfo, page, img, pageLabels, metadata) => {
  const tag = {
    ...tagInfo,
    _id: mongoose.Types.ObjectId().toString(),
    page,
    fieldId: field._id,
  };

  let smartTagValue = tag.smartTagValue ?? null;
  if (!smartTagValue) {
    const regionInPixels = toPixels(tagInfo, [img]);
    smartTagValue = await getValueForCoordinates(regionInPixels, pageLabels);
  }

  Object.assign(tag, { value: smartTagValue, ocrValue: smartTagValue });
  Object.assign(tag, { ...processValue(field, tag.page, tag.value, false, tag, metadata) });

  return tag;
};

export const updateFields = (fields) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_DOCUMENT_FIELDS,
    payload: { fields },
  });

  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
};

export const appendFields = (repeatedGroups) => (dispatch, getState) => {
  const newFields = [];
  repeatedGroups.forEach((group) => newFields.push(...group));

  dispatch({
    type: APPEND_DOCUMENT_FIELDS,
    payload: { newFields },
  });
  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
};

export const repeatField = async (field) => {
  const f = { ...field };
  f.repeatParent = f._id;
  f.srcFieldId = field.srcFieldId || field._id;
  f.order = field.order ? field.order + 1 : 1;
  delete f._id;
  delete f.value;

  return generateTempField(f);
  //dispatch(selectTempField(generatedField));
};

export const upsertTag = (tag, select, regionInPixels, isNew,isSmartTag = false) => async (dispatch, getState) => {
  const { document } = getState();
  const fields = document?.activeTemplate?.fields;
  let field;
  let smartTagValue = tag.smartTagValue ?? null;
  if (smartTagValue) {
    delete tag.smartTagValue;
  }

  const newField = document.activeField;
  let pageNumber = document?.activePage || 1;
  const repeatedFieldId = document.repeatedFieldId;

  dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });

  if (isNew) {
    console.time('create new tag');
    if (newField && repeatedFieldId) {
      field = fields.find((f) => f._id === repeatedFieldId);
    } else if (newField && !repeatedFieldId) {
      const firstField = fields.find((f) => f._id === newField._id && !f.tags.length);
      if (!firstField) {
        field = await repeatField(newField);
        await dispatch(appendFieldToTemplate(field));
      } else {
        field = firstField;
      }

      tag.fieldId = field._id;
    } else {
      field = fields.find((f) => f._id === tag.fieldId);
    }

    const lastTagIndex = field.tags.length > 0 ? field.tags.length - 1 : 0;
    const ObjectId = new mongoose.Types.ObjectId();
    tag._id = ObjectId.toString();

    if (field.dataType === 'section') {
      const newtag = _.cloneDeep(tag);
      tag = { ...newtag, page: pageNumber };
      tag._id = ObjectId.toString();

      if (!field.tags[lastTagIndex]) {
        tag = assignTagSectionWithKey(field, tag, 'start', lastTagIndex);
        tag.section.start.isTable = false;
      } else {
        if (
          field.tags[lastTagIndex].section &&
          (field.tags[lastTagIndex].section.start || field.tags[lastTagIndex].section.end)
        ) {
          if (!field.tags[lastTagIndex].section.end) {
            tag = assignTagSectionWithKey(field, tag, 'end', lastTagIndex);
          } else if (!field.tags[lastTagIndex].section.start) {
            tag = assignTagSectionWithKey(field, tag, 'start', lastTagIndex);
          } else {
            tag = assignTagSectionWithKey(field, tag, 'start', lastTagIndex);
            if (tag.section.end) {
              delete tag.section.end;
            }
          }
        }
        if (field.tags[lastTagIndex].section.start) {
          dispatch(deSelectField());
        }
      }
    }

    await dispatch(appendTag(tag, field._id));

    if (!field.multiple && field.tags[0] && field.dataType !== 'section') {
      await dispatch(deleteTag(field._id, field.tags[0]._id, true));
    }
    console.timeEnd('create new tag');
  } else {
    field = getFields(getState().document).find((f) => f._id === tag.fieldId);
    const lastTagIndex = field.tags.length > 0 ? field.tags.length - 1 : 0;
    if (field.tags && field.tags.length > 0 && field.tags[lastTagIndex].section) {
      const section = _.cloneDeep(field.tags[lastTagIndex].section);
      if (section.start && section.start._id === tag._id) {
        section.start = _.cloneDeep(tag);
      }
      if (section.end && section.end._id === tag._id) {
        section.end = _.cloneDeep(tag);
      }
      field.tags[lastTagIndex].section = section;
    }
  }

  console.time('upsert tag');

  regionInPixels.page = pageNumber;

  // get it again, in case state changed
  //let field = getFields(getState().document).find((f) => f._id === tag.fieldId);
  if (field.dataType === 'table') {
    tag.page = pageNumber;
    tag.hasValidValue = tag ? true : field.tags.length > 0;
    tag.hasValidOcrValue = tag ? true : field.tags.length > 0;
    tag.value = `table on page ${pageNumber}`;
    tag.ocrValue = `table on page ${pageNumber}`;
  } else if (field.dataType !== 'section') {
    const pageLabels = await getPageLabels(getState);
    let value =
      (field.dataType !== 'area' && smartTagValue) ??
      (await getValueForCoordinates(regionInPixels, pageLabels[pageNumber]));
    tag = { ...tag, page: pageNumber, value: value, ocrValue: value };
    tag = {
      ...tag,
      ...processValue(field, pageNumber, value, false, tag, getState().document.metadata),
    };
  }
  await dispatch({
    type: UPSERT_TAG,
    payload: { fieldId: field._id, tag },
  });



  if(isSmartTag){
    const hash = hashFromCoordinates(tag);
    dispatch({type: SET_TAGS_BY_REGIONS, payload: {[hash]: { fieldId: field._id, _id:tag._id,srcFieldId:field.srcFieldId }}})
  }

  if (select) {
    dispatch(selectTag(field._id, tag, () => {}, true));
  }

  // if(!newField){
  //   await dispatch({ type: DESELECT_FIELD });
  // }

  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
  console.timeEnd('upsert tag');
};

export const upsertTagTextSpan = (tag, regionInPixels) => async (dispatch, getState) => {
  const { document } = getState();
  const fields = document?.activeTemplate?.fields;
  const newField = document.activeField;
  const repeatedFieldId = document.repeatedFieldId;

  let field;
  if (newField && repeatedFieldId) {
    field = fields.find((f) => f._id === repeatedFieldId);
  } else if (newField && !repeatedFieldId) {
    const firstField = fields.find((f) => f._id === newField._id && !f.tags.length);
    if (!firstField) {
      field = await repeatField(newField);
      await dispatch(appendFieldToTemplate(field));
    } else {
      field = firstField;
    }
  } else {
    field = fields.find((f) => f._id === tag.fieldId);
  }

  const pageLabels = await getPageLabels(getState);
  tag.tagTitle = field.name;
  const ObjectId = new mongoose.Types.ObjectId();
  tag._id = ObjectId.toString();
  let value = await getValueForCoordinates(regionInPixels, pageLabels[tag.page], true);
  tag = { ...tag, value: value, ocrValue: value };
  tag = {
    ...tag,
    ...processValue(field, tag.page, value, false, tag, getState().document.metadata),
  };
  await dispatch(appendTag(tag, field._id));
};

export const deleteTag =
  (fieldId, tagId, saveLoadingProcess, deleteField = false,deleteSmartTag=false,tag) =>
  async (dispatch,getState) => {
    console.time('delete tag');
    dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });


    dispatch({
      type: DELETE_TAG,
      payload: { fieldId, tagId },
    });



    if (deleteField) {
      const activeField = getState().document?.activeField;
      !activeField && await dispatch({ type: DESELECT_FIELD });
      dispatch(deleteDocumentFields([fieldId]));
    }

    if (!saveLoadingProcess) {
      const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
      dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
    }

    if(deleteSmartTag){
      const tagHash = hashFromCoordinates(tag);
      dispatch({type: SET_TAGS_BY_REGIONS, payload: {[tagHash]: undefined}})
    }
    console.timeEnd('delete tag');
  };

export const setActiveSmartTag = (smartTag) => (dispatch, getState) => {
  dispatch({ type: SET_ACTIVE_SMART_TAG, payload: smartTag });

  if (!smartTag) {
    const { document } = getState();
    if (document.tagsBringedToBack) {
      dispatch(tagsBringStateToFront());
    }
  }
};

export const selectTemplate = (templateId) => async (dispatch, getState) => {
  await innolytiqApi.put(`documents/${getState().document._id}/active/${templateId}`);
  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: REINITIALIZE_CHECKPOINT, payload: { activeTemplate, tags,activePage } });
};

export const selectField = (field, goToPage) => (dispatch, getState) => {
  if (getState().document.activeTemplate && getState().document.activeTemplate.activeField === field._id) {
    return;
  }

  dispatch({
    type: SELECT_FIELD,
    payload: field._id,
  });

  const tag = field.tags[0];

  if (tag && goToPage) {
    dispatch(selectTag(field._id, tag, goToPage, true));
  }

  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
};

export const selectTempField = (field) => (dispatch, getState) => {
  if (getState().document.activeTemplate && getState().document.activeTemplate.activeField === field._id) {
    return;
  }
  dispatch({
    type: SELECT_TEMP_FIELD,
    payload: field,
  });
};

export const selectTag = (fieldId, tag, goToPage, ignoreHistory) => (dispatch, getState) => {
  if (getState().document.activeTemplateId) {
    if (getState().document.activeTagId === tag._id) {
      return;
    }

    const activeTemplate = getActiveTemplate(getState().document);
    const field = _.find(activeTemplate.fields, { _id: fieldId });

    if (field) {
      if (goToPage) {
        goToPage(tag.page - 1);
      }
      dispatch({
        type: SELECT_FIELD,
        payload: fieldId,
      });
      dispatch(setSelectTag(tag));
    }
  }

  if (!ignoreHistory && !getState().document.activeFieldId === fieldId) {
    const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
    dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
  }
};

export const navigateToSmartTag = (tag) => async (dispatch,getState)=>{
  const { document: {activePage}} = getState();
  const samePage = activePage === tag.page;
  if(!samePage)
  await dispatch(changePage(tag.page - 1))

  const element = document.getElementById(`smart-tag-${tag._id}`);
  await element.scrollIntoView({ block: 'nearest', behavior: samePage ? 'smooth' : 'instant' });
}

export const setSelectTag =
  ({ _id, page }) =>
  (dispatch) => {
    dispatch({
      type: SELECT_TAG,
      payload: {
        activeTagId: _id,
        activePage: page,
      },
    });

    dispatch(deleteEmptyTag());
  };

export const changePage = (pageNumber) => async (dispatch, getState) => {
  if (isNaN(pageNumber)) {
    return;
  }
  let { document } = getState();
  const { taggingEditorState } = document;
  const currentPage = pageNumber + 1;
  if (currentPage !== document.activePage) {
    dispatch({
      type: CHANGE_PAGE,
      payload: currentPage,
    });

    if (taggingEditorState === 'pixelPerfect') dispatch(showLabels(document.src, currentPage));

    withDelay(
      `update_active_page_${document._id}`,
      () => {
        innolytiqApi.put(`documents/page/${document._id}/${currentPage}`);
      },
      3000
    );
  }
};

export const checkDocumentAccessibility = ({ isLocked, collectionId }) => {
  if (!isLocked) {
    return true;
  }

  const path = collectionId?._id ? `/collection/${collectionId?._id}` : '/';
  history.push(path);

  return false;
};

export const queryDocument = (documentId, appId) => async (dispatch) => {
  try {
    const response = await innolytiqApi.get(`app/${appId}/document/${documentId}`);
    if (!response.data || isEmpty(response.data)) return;

    const isAccessible = checkDocumentAccessibility(response.data?.document);
    if (!isAccessible) {
      return;
    }

    if (ENABLE_LOCK_DOCUMENT_BY_USER && !response.data.lockedByUser) {
      dispatch(lockDocumentByUser(documentId));
    }

    const { document, app } = response.data;

    //TODO: Change to get the active template id directly from app
    document.activeTemplateId = app.rows[0].fields[0].templateId;

    const activeTemplate = document.templates.find((tObj) => tObj._id === document.activeTemplateId) || document.templates[0];
    const tagsByRegions = getTagsByRegions(activeTemplate.fields);

    dispatch(cleanDocument());
    dispatch({ type: QUERY_DOCUMENT, payload: document });
    dispatch({ type: SET_TAGS_BY_REGIONS, payload: tagsByRegions });
  } catch (e) {
    console.log(e);
    if (e.response.status === 480) {
      history.push('/error?documentLockedByUser=true');
    } else {
      history.push('/error?runTimeError=true');
    }
  }
};

export const queryTemplatesNames = async () => {
  try {
    const { data } = await innolytiqApi.get('templates/names');
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const upsertDocumentFields = (upsertFields) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });

  //TODO:Remove redundant code. foreach

  const fields = [...getFields(getState().document)];

  let isSmartTagChanged = false;
  let groups = [];
  upsertFields.forEach((field) => {
    if (field.group && !field.groupKey) {
      fields.forEach((currentField) => {
        if (currentField.group === field.group && !groups.includes(currentField.groupKey)) {
          groups.push(currentField.groupKey);
          upsertFields.push({ ...field, groupKey: currentField.groupKey });
        } else if (field.group && !groups.includes(field.groupKey)) {
          field.groupKey = uuidv4();
          groups.push(field.groupKey);
          upsertFields.push({ ...field, groupKey: field.groupKey });
        }
      });
      upsertFields = upsertFields.filter((item) => item !== field);
    } else if (!field.group) {
      field.groupKey = '';
      if (groups.includes(field.groupKey)) {
        groups = groups.filter((key) => key !== field.groupKey);
      }
    }
  });

  upsertFields.forEach((field) => {
    let index = field._id ? fields.findIndex((f) => f._id.toString() === field._id.toString()) : -1;
    if (field._id && index !== -1) {
      field.srcFieldId = fields[index].srcFieldId;

      if (!isSmartTagChanged && isChangedSmartTag(fields[index].smartTag, field.smartTag)) {
        isSmartTagChanged = true;
      }

      fields[index] = field;
    } else {
      const ObjectId = new mongoose.Types.ObjectId();
      field._id = ObjectId.toString();
      field.srcFieldId = field.srcFieldId || field._id;
      field.tags = [];

      if (!isSmartTagChanged && field.smartTag && field.smartTag.dataStructure) {
        isSmartTagChanged = true;
      }

      fields.push(field);
    }
  });
  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
  await dispatch({ type: UPSERT_DOCUMENT_FIELDS, payload: { fields } });

  return {
    isSmartTagChanged,
    fields: getFields(getState().document),
  };
};

export const deleteDocumentFields = (fieldIds) => async (dispatch, getState) => {
  const fields = getState().document?.activeTemplate?.fields;

  const field = fields.find((f) => {
    return f._id === fieldIds[0];
  });
  if (field._id === field.srcFieldId) return;
  else if (field.dataType === 'text-span' && field.tags.length >= 1) {
    return;
  }

  dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });
  await dispatch({ type: DELETE_FIELDS, payload: { fieldIds } });

  // const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  // dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
};

export const resetTemplate = (documentId, templateId, collectionId) => async (dispatch) => {
  //dispatch({ type: DETACH_TEMPLATE, payload: { templateId, isLocked: false } });
  try {
    const { data: document } = await innolytiqApi.post(`documents/${documentId}/template/reset/${templateId}`);
    if (!document || document.error) return;
    const activeTemplate = document.templates.find(
        (tObj) => tObj.template && tObj.template._id.toString() === templateId.toString()
    );
    dispatch({
      type: RESET_ACTIVE_TEMPLATE,
      payload: { activeTemplate, isLocked: document.isLocked },
    });

    dispatch({
      type: RESET_TAGS_BY_REGIONS,
    });

    // const attachedTemplate = document.templates.find(
    //   (tObj) => tObj.template && tObj.template._id.toString() === templateId.toString()
    // );
    //
    // await dispatch({
    //   type: APPLY_TEMPLATE,
    //   payload: { document, templateId, attachedTemplate, isLocked: document.isLocked },
    // });

    return { activeTemplate, lockedByUser: document.lockedByUser}
  } catch (e) {
    console.log(e, 'error');
    if (ENABLE_LOCK_DOCUMENT_BY_USER && e.response.status === 480) {
      appendToast(DO_NOT_SAVE_DOCUMENT_CHANGES_TEXT, {
        autoClose: 7000,
        type: 'error',
        onClose: () => {
          if (!webUrl) {
            history.push('/error?documentLockedByUser=true');
            return false;
          }
          window.location.replace(`${webUrl}/collection/${collectionId}`);
        },
      });
    }
  }
};

export const toggleTagsVisibility = () => (dispatch) => {
  dispatch({ type: TOGGLE_TAGS_VISIBILITY });
};

export const setTaggingEditorState =
  (state = '') =>
  (dispatch, getState) => {
    if (!getState().document) {
      return;
    }
    dispatch({ type: TAGGING_EDITOR_STATE, payload: state });

    if (state !== 'pixelPerfect') {
      return;
    }

    const { src, activePage } = getState().document;
    if (!src || !activePage) {
      return;
    }
    dispatch(showLabels(src, activePage));
  };

const getFields = (document) => {
  const { activeTemplate } = document;
  let fields = [];
  if (activeTemplate) {
    fields = (activeTemplate && activeTemplate.fields) || [];
  } else if (document.fields) {
    fields = document.fields;
  }

  return fields;
};

const generateFirstSmartTagAsActive = (smartTags) => {
  if (!smartTags) {
    return null;
  }

  const [firstReference] = smartTags?.reference || [];
  if (!firstReference) {
    return null;
  }

  const { dataField, dataStructure, referenceFieldsData } = firstReference;

  const originFields = smartTags?.origin[`${dataStructure}_${dataField}`];
  const groupedReferences = _.groupBy(referenceFieldsData, ({ renderType }) => renderType);
  const { renderType } = referenceFieldsData[0];

  if (!originFields?.length) {
    return null;
  }
  return {
    originFields,
    dataStructure,
    dataField,
    hash: [dataStructure, dataField, renderType].join('_'),
    referenceFieldsData: groupedReferences[renderType],
  };
};

export const queryReferenceSmartTags = () => async (dispatch, getState) => {
  const { document } = getState();
  if (!document) {
    return;
  }

  let smartTags = [];
  const fields = document?.originFields;

  if (fields && fields.length) {
    const mappedSmartTags = fields
      .filter(({ smartTag }) => smartTag && Object.keys(smartTag).length && smartTag.dataStructure)
      .map(({ smartTag: { dataField, dataStructure, renderType }, _id }) => {
        return {
          dataField,
          dataStructure,
          referenceFieldData: {
            _id,
            renderType: renderType || 'cells',
          },
        };
      });

    _.chain(mappedSmartTags)
      .groupBy(({ dataField }) => dataField)
      .forEach((dataField) => {
        const smartTag = dataField.shift();
        smartTag.referenceFieldsData = [smartTag.referenceFieldData];
        delete smartTag.referenceFieldData;

        if (!dataField.length) {
          return smartTags.push(smartTag);
        }
        dataField.forEach(({ referenceFieldData }) => smartTag.referenceFieldsData.push(referenceFieldData));
        smartTags.push(smartTag);
      })
      .value();
  }

  await dispatch(setReferenceSmartTags(smartTags));
  await dispatch(querySmartTagsPixelPerfectOptionState());
  await dispatch({ type: SET_IS_SMART_TAG, payload: !!smartTags.length });

  return smartTags;
};
export const setReferenceSmartTags = (smartTags) => async (dispatch) => {
  await dispatch({ type: SET_REFERENCE_SMART_TAGS, payload: smartTags });
};

export const querySmartTagsPixelPerfectOptionState = () => async (dispatch, getState) => {
  if (!getState().document) {
    return;
  }

  const { smartTags } = getState().document;
  const fields = getFields(getState().document);

  const oldState = !!(smartTags && smartTags.pixelPerfectOptionDisabled);
  // Need when all fields exclude one which not a smartHover and was deleted it.
  const pixelPerfectOptionDisabled = !fields.find(
    ({ smartTag, dataType }) => (!smartTag || !smartTag.dataStructure) && dataType !== 'area'
  );

  if (oldState !== pixelPerfectOptionDisabled) {
    await dispatch(setSmartTagsPixelPerfectOptionState(pixelPerfectOptionDisabled));
  }

  return pixelPerfectOptionDisabled;
};

export const setSmartTagsPixelPerfectOptionState = (state) => (dispatch) => {
  dispatch({ type: SET_PIXELPERFECT_OPTION_DISABLED, payload: !!state });
};

export const queryOriginSmartTags =
  (firstOptionActivate = false) =>
  async (dispatch, getState) => {
    const {
      document: { _id, smartTags },
    } = getState();
    const { isSmartTag, origin } = smartTags ?? {};
    if (!isSmartTag) {
      await dispatch(setOriginSmartTags({}));
      return {};
    }

    let referenceTags = smartTags.reference;
    referenceTags = !referenceTags.length
      ? referenceTags
      : referenceTags.map(({ dataField, dataStructure }) => ({ dataField, dataStructure }));

    if (origin && Object.keys(origin).length) {
      // Filter already downloaded origins
      referenceTags = referenceTags.filter(({ dataField, dataStructure }) => !origin[`${dataStructure}_${dataField}`]);
    }

    if (!referenceTags.length) {
      return origin;
    }

    const response = await innolytiqApi.post(`documents/${_id}/getOriginFields`, {
      smartTags: referenceTags,
    });

    const data = response && response.data;
    const originTags = {
      ...origin,
      ...((data && data.smartTags) ?? []),
    };

    await dispatch(setOriginSmartTags(originTags));

    if (firstOptionActivate) {
      const {
        document: { smartTags: storedSmartTags },
      } = getState();

      if (!storedSmartTags.activeTag) {
        const generatedActiveTag = generateFirstSmartTagAsActive(storedSmartTags);

        if (generatedActiveTag) {
          dispatch(setActiveSmartTag(generatedActiveTag));
        }
      }
    }

    return originTags;
  };

export const setOriginSmartTags = (originTags) => async (dispatch) => {
  await dispatch({ type: SET_ORIGIN_SMART_TAGS, payload: originTags });
};

export const updateSmartTags =
  (_options = {}) =>
  async (dispatch, getState) => {
    const resetAll = typeof _options.resetAll === 'boolean' ? _options.resetAll : false;
    const updateActiveTag = typeof _options.updateActiveTag === 'boolean' ? _options.updateActiveTag : true;
    const firstOptionActive = typeof _options.firstOptionActive === 'boolean' ? _options.firstOptionActive : false;
    const updateReferences = typeof _options.updateReferences === 'boolean' ? _options.updateReferences : true;
    const updateOrigins = typeof _options.updateOrigins === 'boolean' ? _options.updateOrigins : true;
    const updatePixelPerfectOptionState =
      updateOrigins ||
      (typeof _options.updatePixelPerfectOptionState === 'boolean' ? _options.updatePixelPerfectOptionState : true);

    if (resetAll) {
      await dispatch(clearSmartTags());
    }

    if (updateReferences || resetAll) {
      await dispatch(queryReferenceSmartTags());
    } else if (updatePixelPerfectOptionState) {
      await dispatch(querySmartTagsPixelPerfectOptionState());
    }

    if (updateOrigins || resetAll) {
      await dispatch(queryOriginSmartTags());
    }

    if (updateActiveTag && getState().document?.smartTags?.activeTag) {
      await dispatch(reselectActiveTag(getState().document?.smartTags?.activeTag));
    } else if (firstOptionActive) {
      const {
        document: { smartTags: storedSmartTags },
      } = getState();

      const generatedActiveTag = generateFirstSmartTagAsActive(storedSmartTags);
      if (generatedActiveTag) {
        await dispatch(setActiveSmartTag(generatedActiveTag));
      }
    }
  };

export const reselectActiveTag = (activeTag) => async (dispatch, getState) => {
  const {
    document,
    document: { smartTags },
  } = getState();

  if (!smartTags) {
    return;
  }

  activeTag = activeTag || smartTags.activeTag || null;

  if (!activeTag || !activeTag.referenceFieldsData.length) {
    await dispatch(setActiveSmartTag(null));
    return;
  }
  /*
  activeTag = {
    ...activeTag,
    ...(reference.find(
      (ref) => ref.dataField === activeTag.dataField && ref.dataStructure === activeTag.dataStructure
    ) || {}),
  };*/

  const fields = getFields(document);
  const referenceFieldIds = activeTag.referenceFieldsData.map(({ _id }) => _id);
  const haveFieldsWithCurrentFilter = fields.find(
    ({ _id, smartTag }) => smartTag && smartTag.dataStructure && referenceFieldIds.includes(_id)
  );

  if (!haveFieldsWithCurrentFilter) {
    await dispatch(setActiveSmartTag(null));
    return;
  }

  await dispatch(setActiveSmartTag(activeTag));
};

export const clearSmartTags = () => async (dispatch) => {
  await dispatch({ type: CLEAR_SMART_TAGS });
};

export const showLabels = (documentId, page) => async (dispatch) => {
  const response = await innolytiqApi.post(`documents/labels/${documentId}/${page}`);
  dispatch({ type: SHOW_LABELS, payload: response.data });
};

export const clearLabels = () => async (dispatch, getState) => {
  const { document } = getState();
  if (document?.labels?.length) {
    dispatch({ type: CLEAR_LABELS });
  }
};

export const updateValue =
  (fieldId, tagId, value, onlyValue, resetFx, previousScale, isOcr) => async (dispatch, getState) => {
    dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });
    const fields = getFields(getState().document);

    let result;
    let field = fields.find((f) => f._id.toString() == fieldId);
    if (!field) return false;
    if (tagId) {
      let tag = field.tags.find((t) => t._id.toString() === tagId);
      result = processValue(
        field,
        tag.page,
        value,
        onlyValue,
        tag,
        getState().document.metadata,
        resetFx,
        previousScale,
        isOcr
      );
    } else {
      result = processValue(
        field,
        null,
        value,
        onlyValue,
        null,
        getState().document.metadata,
        resetFx,
        previousScale,
        isOcr
      );
    }

    dispatch({
      type: UPDATE_VALUE,
      payload: {
        fieldId,
        tagId,
        value: result.value,
        ocrValue: result.ocrValue,
        hasValidValue: result.hasValidValue,
        hasValidOcrValue: result.hasValidOcrValue,
        onlyValue,
      },
    });

    // const {document: {activeTemplate},annotation: {tags}} = getState();
    // dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags} });
  };

export const resetFieldScale = (fieldId) => async (dispatch) => {
  dispatch({ type: RESET_FIELD_SCALE_VALUE, payload: fieldId.toString() });
};

export const changeClassificationField = (field) => async (dispatch) => {
  const { isSmartTagChanged } = await dispatch(upsertDocumentFields([field]));

  if (isSmartTagChanged) {
    await dispatch(
      updateSmartTags({
        updateOrigins: false,
      })
    );
  }
};

export const resetGroupTags = (groupKey) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });

  const fields = getFields(getState().document);
  const groupFields = fields.filter((field) => field.groupKey === groupKey);

  groupFields.forEach((field) => {
    field.tags = [];
    if (field.fieldType !== 'computation' && field.fieldType !== 'extraction and computation') {
      field.value = '';
    }
    if (field.dataType === 'number') {
      dispatch(resetFieldScale(field._id));
    }
  });
  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
};

export const generateRepeatedFields = (groupFields, count = 1) => {
  const newFields = [];

  [...Array(count)].forEach(() => {
    const newGroupKey = uuidv4().toString();

    newFields.push(
      groupFields.map((field) => ({
        ...field,
        _id: mongoose.Types.ObjectId().toString(),
        groupKey: newGroupKey,
        value: '',
        srcFieldId: field.srcFieldId || field._id,
        tags: [],
        hasValidValue: false,
        order: field.order + 0.001,
        repeatParent: field._id,
      }))
    );
  });

  return newFields;
};

export const repeatDocumentGroupFields = (groupKey) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });

  const fields = getFields(getState().document);
  const gropuFields = fields.filter((field) => field.groupKey === groupKey);

  if (!gropuFields.length) {
    return { newFields: [] };
  }
  const [newFields] = generateRepeatedFields(gropuFields, 1);

  await dispatch({ type: REPEAT_DOCUMENT_FIELDS, payload: { newFields } });
  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
  return { newFields };
};

export const queryTemplate = (id) => async (dispatch) => {
  const response = await innolytiqApi.get(`templates/${id}`);
  dispatch({ type: QUERY_TEMPLATE, payload: { template: response.data } });
  return response.data;
};

export const queryDocumentTemplateUpdates = (id, templateId) => {
  return new Promise(async (resolve) => {
    const response = await innolytiqApi.get(`documents/${id}/update/${templateId}`);
    if (response.status === 200) {
      resolve(response.data);
    }
  });
};

export const upsertDocumentMetadata = (id, metadata) => async (dispatch, getState) => {
  const response = await innolytiqApi.post(`documents/metadata/${id}`, {
    metadata,
  });

  dispatch({ type: QUERY_DOCUMENT, payload: { ...getState().document, metadata, isLocked: response.data.isLocked } });
};

function getPageLabels(getState) {
  return new Promise((resolve) => {
    const next = () => {
      const { document } = getState();
      if (document.pageLabels) {
        resolve(document.pageLabels);
      } else {
        setTimeout(next, 1000);
      }
    };
    next();
  });
}

export const updateTable = (fieldId, tagId, page, data, img, type, takeOcr) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });
  withDelay(
    tagId,
    async () => {
      const imgW = img[0].naturalWidth,
        imgH = img[0].naturalHeight;

      if (!takeOcr && type === 'text') {
        dispatch({
          type: UPDATE_FIELD_TABLE_TYPE,
          payload: { fieldId, data: data },
        });
      }

      const fields = getFields(getState().document);
      const field = fields.find((f) => f._id.toString() === fieldId);

      let tag = field.tags.find((t) => t._id.toString() === tagId);

      if (tag) {
        if (!data.updated) {
          const pageLabels = await getPageLabels(getState);
          tag.table = await getTableValues(data, imgW, imgH, page, pageLabels[tag.page]);
        } else {
          tag.table = data;
        }
        tag.isTable = true;
        if (type === 'text') {
          if (takeOcr) {
            dispatch({
              type: UPDATE_FIELD_TABLE_TYPE,
              payload: { fieldId, type, data: tag.table },
            });
          }
        }
      }
      const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
      dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
    },
    500
  );
};

export const updateFieldTableType = (fieldId, type, img) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });

  const fields = getFields(getState().document);
  const field = fields.find((f) => f._id === fieldId);

  if (field.tags[0]) {
    if (type === 'text') {
      if (!field.tags[0]?.table?.tbody?.tr || field.tags[0]?.table?.tbody?.tr.length === 0) {
        const tag = field.tags[0];
        dispatch(updateTable(fieldId, tag._id, tag.page, tag.table, img, type, true));
      } else {
        dispatch({
          type: UPDATE_FIELD_TABLE_TYPE,
          payload: { fieldId, type, data: field?.tags[0]?.table },
        });
      }
    } else {
      dispatch({ type: UPDATE_FIELD_TABLE_TYPE, payload: { fieldId, type } });
    }
  }
};

export const forceOcr = (id, sha, ocr) => async () => {
  await innolytiqApi.get(`upload/ocr/${id}/${sha}/${ocr}`);
};

export const deSelectField = () => (dispatch) => {
  dispatch({
    type: DESELECT_FIELD,
  });
};

export const dataTransfer = (id) => async (dispatch) => {
  dispatch({
    type: DATA_TRANSFER_STATUS,
    payload: { transferStatus: { statusCode: -1, message: '', isSuccess: null }, id: id },
  });
  try {
    const response = await innolytiqApi.get(`documents/${id}/dataTransfer`);
    dispatch({ type: DATA_TRANSFER_STATUS, payload: { id: id, transferStatus: response.data } });
  } catch (error) {
    console.log(error, 'error');
    dispatch({
      type: DATA_TRANSFER_STATUS,
      payload: { id: id, transferStatus: { statusCode: error.response.status, message: 'Something went wrong' } },
    });
  }
};

export const queryDocumentLabels = (sha) => async (dispatch, getState) => {
  const response = await innolytiqApi.get(`documents/labels/${sha}`);
  await dispatch({ type: QUERY_DOCUMENT_LABELS, payload: response.data });
  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();

  dispatch({ type: REINITIALIZE_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
};

export const tagsBringStateToBack = () => (dispatch) => {
  dispatch({ type: TAGS_BRING_TO_BACK });
};

export const tagsBringStateToFront = () => (dispatch) => {
  dispatch({ type: TAGS_BRING_TO_FRONT });
};

export const tagsBringSateToggle = () => (dispatch, getState) => {
  if (!getState().document.tagsBringedToBack) {
    dispatch(tagsBringStateToBack());
  } else {
    dispatch(tagsBringStateToFront());
  }
};

export const refreshFormulaFieldComponents = (documentId, fieldId, templateId) => async (dispatch) => {
  const response = await innolytiqApi.post(`documents/${documentId}/queryfieldcomponents`, { fieldId, templateId });
  await dispatch({ type: REFRESH_FIELD_COMPONENTS, payload: { data: response.data, fieldId, templateId } });
};

export const rotatePages =
  (documentId, documentSrc, description, collectionId, ocr, rotationAngleForPage, documentName, callback) =>
  async () => {
  try {
    const response = await innolytiqApi.post(`documents/rotatepages/${documentId}`, {
      rotationAngleForPage,
      documentName,
      collectionId,
      description,
      documentSrc,
      ocr,
    });
    if (ENABLE_LOCK_DOCUMENT_BY_USER && !response.data.lockedByUser) {
      return appendToast(SAVE_DOCUMENT_CHANGES_TEXT, {
        onClose: () => {
          callback();
        },
      });
    }
    if (response) {
      callback();
    }
  }
  catch (e) {
    console.log(e, 'error');
    if (ENABLE_LOCK_DOCUMENT_BY_USER && e.response.status === 480) {
      appendToast(DO_NOT_SAVE_DOCUMENT_CHANGES_TEXT, {
        autoClose: 7000,
        type: 'error',
        onClose: () => {
          callback();
        },
      });
    }
  }

  };

export const setFieldScale = (fieldId, scale, callback) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_TEMPLATE_STATUS, payload: false });
  dispatch({
    type: SET_FIELD_SCALE,
    payload: {
      fieldId,
      scale,
    },
  });
  callback();
  const {document: {activeTemplate,activePage},annotation: {tags}} = getState();
  dispatch({ type: ADD_CHECKPOINT, payload: {activeTemplate,tags,activePage} });
};

export const lockDocumentByUser = (documentId) => async () => {
  try {
    await innolytiqApi.post(`documents/${documentId}/lockedByUser`);
  } catch (e) {
    console.log(e);
  }
};

export const unlockDocumentByUser = (documentId) => async () => {
  try {
    await innolytiqApi.delete(`documents/${documentId}/lockedByUser`);
  } catch (e) {
    console.log(e);
  }
};

export const getLockedDocumentByUser = (documentId, cb) => async () => {
      try {
        const { data } = await innolytiqApi.get(`documents/${documentId}/lockedByUser`);
        cb(data);
      } catch (e) {
        console.log(e);
      }
    };
