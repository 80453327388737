export const getBaseURL = () => {
  return process.env.REACT_APP_INNOLYTIQ_API_URI || window._env_.INNOLYTIQ_API_URI;
};
export const getWebUrl = () => {
  return process.env.REACT_APP_INNOLYTIQ_WEB_URI || window._env_.INNOLYTIQ_API_URI;
};

export const getFavicon = (isDarkTheme) => {
  if (isDarkTheme) {
    return process.env.REACT_APP_FAVICON || window._env_.FAVICON || 'dot-rgb-cognaize-blue.svg';
  }
  return process.env.REACT_APP_FAVICON || window._env_.FAVICON || 'dot-rgb-cognaize-blue.svg';
};

export const getLogo = () => {
  return process.env.REACT_APP_LOGO || window._env_.LOGO || 'cognaize-logo-white.svg';
};

export const getTitle = () => {
  return process.env.REACT_APP_TITLE || window._env_.TITLE || 'Cognaize';
};

export const getOcrTypes = () => {
  return process.env.REACT_APP_OCR_TYPES || window._env_.OCR_TYPES || '[]';
};

export const getTransferValidation = () => {
  return process.env.REACT_APP_TRANSFER_VALIDATION || window._env_.TRANSFER_VALIDATION;
};

export const getChatBotUrl = () => {
  return process.env.REACT_APP_CHAT_BOT_URL || window._env_.CHAT_BOT_URL;
};

export const isEnabledLockDocumentByUser = () => {
  const isEnabled = process.env.REACT_APP_ENABLE_LOCK_DOCUMENT_BY_USER || window._env_.ENABLE_LOCK_DOCUMENT_BY_USER;;
  return isEnabled?.toString().toLowerCase() === 'true';
};