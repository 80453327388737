import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  queryDocument,
  selectTemplate,
  queryDocumentLabels,
  clearSmartTags,
  resetTemplate,
  queryDocumentTemplateUpdates,
  unlockDocumentByUser,
  lockDocumentByUser,
  getLockedDocumentByUser,
} from '../../../actions/index';
import {
  updateTemplateStatus,
  saveDocument,
  setSaveSuccessful,
  resetDataLostDialog,
  setActiveSmartHoverTemplate,
} from '../../../store/document/actions';
import { cleanDocument } from '../../../actions/cleaners';
import history from '../../../history';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import './Browse.scss';
import './Split.scss';
import { queryReferenceSmartTags, updateSmartTags } from '../../../actions';
import $ from 'jquery';

import { getIsSmartTag, getActiveTemplate, getFields, getActiveSmartTag } from './BrowseUtils';
import {
  openConfirmMoveToCollectionStepModal,
  openConfirmUnsavedDataLostModal,
  openWorkflowTemplateFlowModal,
} from '../../modal/service/ModalControllers';

import BrowserLayout from '../BrowserLayout/BrowserLayout';

import { getStepFork, getWorkflowStepByTemplateId } from '../../../store/document/utils';
import { openInNewTab } from '../../../helpers/common/utils';
import { appendToast } from "../../../commons/appToastify";
import { SAVE_DOCUMENT_CHANGES_TEXT } from "../../../commons/const";
import { getBaseURL, getWebUrl, isEnabledLockDocumentByUser } from "../../../appConfig";

const webUrl = getWebUrl();
const ENABLE_LOCK_DOCUMENT_BY_USER = isEnabledLockDocumentByUser();

class Browse extends Component {
  state = {
    startedAt: Date.now(),
    searchFieldValue: '',
    isSearchSelect: false,
    saveLoader: false,
    isFinishing: false,
    genieRunning: false,
    showFields: 'showAllFields',
    finishButtonClicked: false,
    loadingDocument: true,
    hideGenieDropdown: true,
  };

  unloadTimeOutRef = React.createRef();

  constructor(props) {
    super(props);
    nprogress.configure({ showSpinner: false }).start();
  }

  // ===============================================
  // STATE SETTER METHODS

  selectSearchText = () => {
    this.setState({
      isSearchSelect: !this.state.isSearchSelect,
    });
  };

  // LIFE-CYCLE METHODS

  componentDidMount = async () => {
    window.addEventListener('beforeunload', this.handleBeforeunloadWithoutConfirmPopUp);
    document.addEventListener('visibilitychange', this.handleActivity);
    let needCalcSmartTags = false;
    //await this.props.queryDocument(this.props.match.params.id); // used to get this.props.document.
    const {
      params: { id, appId },
    } = this.props.match;

    await this.props.queryDocument(id, appId);

    await this.props.queryDocumentLabels(this.props.document.src);

    const activeTemplate = getActiveTemplate(this.props.document);
    if (activeTemplate) {
      await this.queryDocumentTemplateUpdates();

      needCalcSmartTags = activeTemplate.fields && !!activeTemplate.fields.length;
    } else {
      needCalcSmartTags = this.props.document.fields && !!this.props.document.fields.length;
    }

    if (needCalcSmartTags) {
      await this.props.queryReferenceSmartTags();
    }

    nprogress.done();
    this.setState({ loadingDocument: false });
  };

  changeTemplate = async (args = {}) => {
    const { templateId = null, firstOptionActive = true } = args;
    await this.changeSelectedTemplateObj(templateId);
    await this.updateSmartTags({ firstOptionActive });
    this.setState({ startedAt: Date.now() });
  };

  async componentDidUpdate(prevProps) {
    const { document, setActiveSmartHoverTemplate } = this.props;
    const activeTemplate = getActiveTemplate(document);
    if (document) {
      if (document.saveBtnActive && !prevProps.document.saveBtnActive) {
        window.addEventListener('unload', this.noTimeout);
        window.removeEventListener('beforeunload', this.handleBeforeunloadWithoutConfirmPopUp);
        window.addEventListener('beforeunload', this.handleBeforeunload);
      } else if (!document.saveBtnActive && prevProps.document.saveBtnActive) {
        window.removeEventListener('unload', this.noTimeout);
        window.removeEventListener('beforeunload', this.handleBeforeunload);
        window.addEventListener('beforeunload', this.handleBeforeunloadWithoutConfirmPopUp);
      }
    }

    if (document?.dataLostDialog) {
      openConfirmUnsavedDataLostModal({
        label: document?.dataLostDialog?.label || 'Data Lost',
        activeTemplateName: activeTemplate?.template?.name,
        onResponse: (confirmed = true) => {
          if (!confirmed) {
            return this.props.resetDataLostDialog();
          }
          this.props.document.dataLostDialog.action();
        },
      });
    }
    const activeSmartHoverTemplateId = document.activeSmartHoverTemplate._id;
    if (activeSmartHoverTemplateId && activeSmartHoverTemplateId === activeTemplate?._id) {
      setActiveSmartHoverTemplate({});
    }
  }

  componentWillUnmount = async () => {
    if (ENABLE_LOCK_DOCUMENT_BY_USER && this.props.document.lockedByUser) {
      this.props.unlockDocumentByUser(this.props.document._id);
    }
    window.removeEventListener('beforeunload', this.handleBeforeunload);
    window.removeEventListener('beforeunload', this.handleBeforeunloadWithoutConfirmPopUp);
    document.removeEventListener('visibilitychange', this.handleActivity);
    window.removeEventListener('unload', this.noTimeout);
    await this.props.cleanDocument();
  };

  handleBeforeunloadWithoutConfirmPopUp = () => {
    try {
      if (!ENABLE_LOCK_DOCUMENT_BY_USER) {
        return;
      }
      fetch(`${getBaseURL()}/documents/${this.props.document._id}/lockedByUser`, {
        keepalive: true,
        method: 'DELETE',
        headers: { 'x-auth': localStorage.getItem('jwtToken'), 'Content-Type': 'application/json' },
        credentials: 'include',
      });
    } catch (error) {
      console.log(error, 'error');
    }
  };

  handleBeforeunload = (e) => {
    if (ENABLE_LOCK_DOCUMENT_BY_USER) {
      this.props.unlockDocumentByUser(this.props.document._id);
      this.unloadTimeOutRef.current = setTimeout(() => {
        setTimeout(() => {
          this.props.lockDocumentByUser(this.props.document._id);
        }, 100);
      }, 2000);
    }
    e.returnValue = 'You are leaving the page';
    return 'You are leaving the page';
  };

  noTimeout = () => {
    clearTimeout(this.unloadTimeOutRef.current);
  };

  handleActivity = () => {
    if (ENABLE_LOCK_DOCUMENT_BY_USER && document.visibilityState === 'visible') {
      this.props.getLockedDocumentByUser(this.props.document._id, (lockedByUser) => {
        if (!lockedByUser) {
          this.props.lockDocumentByUser(this.props.document._id);
        }
      });
    }
  };

  queryDocumentTemplateUpdates = () => {
    // if (!this.props.document) {
    //   return;
    // }

    const activeTemplate = getActiveTemplate(this.props.document);
    const templateId = activeTemplate?._id;

    if (!templateId) {
      return;
    }

    if (this.queryDocumentTemplateUpdatesLoadingInfo) {
      // if already loading the info with the given template, return same promise to avoid duplicate calls
      if (this.queryDocumentTemplateUpdatesLoadingInfo.templateId === templateId) {
        return this.queryDocumentTemplateUpdatesLoadingInfo.promise;
      }

      // if the templateId has chnaged, cancel the previous call
      this.queryDocumentTemplateUpdatesLoadingInfo.cancel();
    }

    let canceled = false;
    const info = {
      templateId,
      cancel() {
        canceled = true;
      },
    };
    this.queryDocumentTemplateUpdatesLoadingInfo = info;

    if (
      this.state.addedFields ||
      this.state.checkedAddFields ||
      this.state.removedFields ||
      this.state.checkedDeleteFields ||
      this.state.changedFields ||
      this.state.checkedChangedFields
    ) {
      this.setState({
        addedFields: null,
        checkedAddFields: null,
        removedFields: null,
        checkedDeleteFields: null,
        changedFields: null,
        checkedChangedFields: null,
      });
    }

    const promise = queryDocumentTemplateUpdates(this.props.document._id, templateId);
    info.promise = promise;

    promise.then((res) => {
      if (canceled) return;

      this.queryDocumentTemplateUpdatesLoadingInfo = null;

      if (res.addedDifference?.length || res.deletedDifference?.length || res.changedFields?.length) {
        this.setState({
          addedFields: res.addedDifference,
          checkedAddFields: res.addedDifference,
          removedFields: res.deletedDifference,
          checkedDeleteFields: res.deletedDifference,
          changedFields: res.changedFields,
          checkedChangedFields: res.changedFields,
        });
      }
    });
  };

  handleReset = async () => {
    if (!this.props.rules.annotate) {
      return;
    }

    const { activeTemplate } = this.props.document;

    if (!activeTemplate) {
      return;
    }

    const { template } = activeTemplate;

    nprogress.configure({ showSpinner: false }).start();

    this.setState({ saveLoader: true });
    const documentId = this.props.match.params.id;

    const resetResult = await this.props.resetTemplate(documentId, template._id, this.props.document.collectionId?._id);
    if (!resetResult || !resetResult.activeTemplate) return this.setState({ saveLoader: false });

    await this.changeTemplate({ templateId: resetResult.activeTemplate._id });

    this.setState({ saveLoader: false });
    await this.props.setSaveSuccessful();
    nprogress.done();

    if (ENABLE_LOCK_DOCUMENT_BY_USER && !resetResult.lockedByUser) {
      appendToast(SAVE_DOCUMENT_CHANGES_TEXT, {
        autoClose: 7000,
        onClose: () => {
          if (!webUrl) {
            history.push('/error?documentNonAccessable');
            return false;
          }
          window.location.replace(`${webUrl}/collection/${this.props.document.collectionId?._id}`);
        },
      });
    }
  };

  finish = (activeTemplate, img, workflowChanges, callback = () => {}) => {
    this.setState({ isFinishing: true });
    const fields = getFields(this.props.document);
    this.props
      .updateTemplateStatus(
        this.props.document._id,
        activeTemplate?.template?._id,
        !activeTemplate?.isFinished,
        img,
        fields,
        workflowChanges,
        this.props.document.collectionId._id
      )
      .then(() => {
        this.setState({
          saveLoader: false,
          isFinishing: false,
          finishButtonClicked: false,
        });
        if (this.props.document.isLocked) {
          const url = this.props.document.collectionId
            ? `/collection/${this.props.document.collectionId._id || this.props.document.collectionId}`
            : '/';
          ENABLE_LOCK_DOCUMENT_BY_USER && this.props.unlockDocumentByUser(this.props.document._id);
          openInNewTab(url, '_self');
        } else {
          callback();
        }
      });
  };

  handleFinish = (e) => {
    e.preventDefault();
    const { activeTemplate } = this.props.document;
    if (!activeTemplate?.isFinished) {
      this.setState({
        saveLoader: true,
      });
    }
    this.setState({
      finishButtonClicked: true,
    });
    let page = this.props.document.activePage;
    const img = $(`#img-${page}`);
    const currentWorkflow = this.props.document.workflows
      ? this.props.document.workflows[this.props.document.workflows.length - 1]
      : null;

    if (!currentWorkflow || activeTemplate?.isFinished) {
      return this.finish(activeTemplate, img);
    }
    const activeTemplateOriginalId = activeTemplate.template?._id || activeTemplate.template;
    const workflowStep = getWorkflowStepByTemplateId(currentWorkflow, activeTemplateOriginalId);

    if (workflowStep) {
      const fork = getStepFork(workflowStep);
      if (fork.type === 'moveToCollection' && fork.moveToCollection?.template === activeTemplateOriginalId) {
        openConfirmMoveToCollectionStepModal({
          onResponse: (confirmed) => {
            if (confirmed) {
              this.finish(activeTemplate, img, { completedMicrotask: null });
            } else {
              this.setState({
                saveLoader: false,
                isFinishing: false,
                finishButtonClicked: false,
              });
            }
          },
        });
        return;
      }
    }

    openWorkflowTemplateFlowModal({
      finishAction: (workflowChanges, callback) => this.finish(activeTemplate, img, workflowChanges, callback),
      changeTemplate: (documentTemplateId) => this.changeTemplate({ templateId: documentTemplateId }),
      onCancel: () => {
        this.setState({
          saveLoader: false,
          isFinishing: false,
          finishButtonClicked: false,
        });
      },
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    this.setState({
      saveLoader: true,
    });
    const activeTemplate = getActiveTemplate(this.props.document);
    let page = this.props.document.activePage;
    const img = $(`#img-${page}`);
    const fields = getFields(this.props.document);

    this.props
      .saveDocument(
        this.props.match.params.id,
        activeTemplate?.template?._id,
        fields,
        img,
        activeTemplate?.version,
        this.state.startedAt,
        this.props.document.collectionId._id,
      )
      .then(() => {
        this.setState({
          startedAt: Date.now(),
          saveLoader: false,
        });
      });
  };

  updateSmartTags = async (options) => {
    await this.props.updateSmartTags({ resetAll: true, ...options });
  };

  changeSelectedTemplateObj = async (selectedTemplateObjId) => {
    if (selectedTemplateObjId === 'show_document_specific_fields') {
      this.showDocumentSpecificFields(selectedTemplateObjId);
      return;
    }

    await this.props.selectTemplate(selectedTemplateObjId);

    await this.queryDocumentTemplateUpdates();
  };

  showDocumentSpecificFields = () => {
    this.props.selectTemplate(null);
  };

  // ========================================
  // RENDER METHODS

  render() {
    const { document } = this.props;

    if (this.state.loadingDocument) {
      return <div>Loading...</div>;
    }

    if (!document || _.isEmpty(document)) return null;

    const fields = getFields(document);
    const isSmartTag = getIsSmartTag(document);
    const activeTemplate = getActiveTemplate(document);
    const activeSmartTag = getActiveSmartTag(document);
    const taggingEditorState = document?.taggingEditorState || '';

    return (
      <BrowserLayout
        fields={fields}
        isSmartTag={isSmartTag}
        activeTemplate={activeTemplate}
        activeSmartTag={activeSmartTag}
        taggingEditorState={taggingEditorState}
        activeFieldId={document.activeFieldId}
        saveSuccessful={document.saveSuccessful}
        saveBtnActive={document.saveBtnActive}
        documentId={document._id}
        checkpoint={this.props.checkpoint}
        rules={this.props.rules}
        state_showFields={this.state.showFields}
        handleReset={this.handleReset}
        upsertField={this.upsertField}
        handleFinish={this.handleFinish}
        handleSave={this.handleSave}
        state_isFinishing={this.state.isFinishing}
        state_finishButtonClicked={this.state.finishButtonClicked}
        selectSearchText={this.selectSearchText}
        state_saveLoader={this.state.saveLoader}
        state_isSearchSelect={this.state.isSearchSelect}
        state_addedFields={this.state.addedFields}
        state_removedFields={this.state.removedFields}
        state_changedFields={this.state.changedFields}
        state_checkedDeleteFields={this.state.checkedDeleteFields}
        state_checkedChangedFields={this.state.checkedChangedFields}
        state_checkedAddFields={this.state.checkedAddFields}
        state_genieRunning={this.state.genieRunning}
        documentSrc={document.src}
        documentType={document.type}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rules: state.rules.annotation,
    document: state.document,
    checkpoint: state.checkpoint,
  };
};

export default connect(mapStateToProps, {
  queryDocument,
  cleanDocument,
  updateTemplateStatus,
  selectTemplate,
  saveDocument,
  setSaveSuccessful,
  resetDataLostDialog,
  queryDocumentLabels,
  updateSmartTags,
  clearSmartTags,
  queryReferenceSmartTags,
  resetTemplate,
  setActiveSmartHoverTemplate,
  unlockDocumentByUser,
  lockDocumentByUser,
  getLockedDocumentByUser,
})(Browse);
