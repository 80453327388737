import { toast, TypeOptions, Theme, ToastPosition, ToastOptions } from 'react-toastify';

type TToast = {
  type?: TypeOptions;
  theme?: Theme;
  hideProgressBar?: boolean;
  autoClose?: number | false;
  position?: ToastPosition;
  onClose?: ToastOptions['onClose'];
};
export const appendToast = (toastContent: string | JSX.Element, params: TToast = {}) => {
  const {
    type = 'success',
    theme = 'dark',
    hideProgressBar = false,
    autoClose = 5000,
    position = 'top-right',
    onClose,
  } = params;

  toast(toastContent, {
    position,
    autoClose,
    hideProgressBar,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme,
    type,
    onClose,
  });
};
